import React, { useState } from 'react';
import InnerHaeder from '../Component/InnerHaeder';
import Highlights from '../Component/Highlights';
import Footer from '../Component/Footer';
import { Link } from 'react-router-dom';
import HeadTag from '../Component/HeadTags';

const Media = () => {
  const [selectedYear, setSelectedYear] = useState('2024');
  const [selectedMonth, setSelectedMonth] = useState('');

  const years = ['2024','2023', '2022',  '2020', '2019', '2018'];
  const months = [
    { name: 'January', value: '01' },
    { name: 'February', value: '02' },
    { name: 'March', value: '03' },
    { name: 'April', value: '04' },
    { name: 'May', value: '05' },
    { name: 'June', value: '06' },
    { name: 'July', value: '07' },
    { name: 'August', value: '08' },
    { name: 'September', value: '09' },
    { name: 'October', value: '10' },
    { name: 'November', value: '11' },
    { name: 'December', value: '12' }
  ];

  const mediaData = [
    { id: 0, title: 'Navbharat Times', date: '2024-09-20', imgSrc: 'Images/media20-9-2024-1.jpg' },
    { id: 1, title: 'Hindustan Times', date: '2024-09-17', imgSrc: 'Images/media17-9-2024-1.jpg' },
    { id: 2, title: 'Navbharat Times', date: '2024-09-17', imgSrc: 'Images/media17-9-2024-2.jpg' },
    { id: 3, title: 'Navbharat Times', date: '2024-09-10', imgSrc: 'Images/media17-9-2024-3.jpg' },
    { id: 4, title: 'Hindustan Times', date: '2024-09-12', imgSrc: 'Images/media2024-09-10.png' },
    { id: 5, title: 'Times Of India', date: '2024-08-12', imgSrc: 'Images/media2024-08-17.jpg' },
    { id: 6, title: 'Hindustan Times', date: '2024-08-10', imgSrc: 'Images/media2024-08-12-2.jpg' },
    { id: 7, title: 'Navbharat Times', date: '2024-08-10', imgSrc: 'Images/media2024-08-12.jpg' },
    { id: 8, title: 'Navbharat Times', date: '2024-08-10', imgSrc: 'Images/media2024-08-10.jpg' },
    { id: 9, title: 'Navbharat Times', date: '2024-08-02', imgSrc: 'Images/media2024-08-02.jpg' },
    { id: 10, title: 'Amar Ujala', date: '2024-06-28', imgSrc: 'Images/media2024-06-28.jpg' },
    { id: 11, title: 'Amar Ujala', date: '2024-06-28', imgSrc: 'Images/media2024-06-24.jpg' },
    { id: 12, title: 'Navbharat Times', date: '2024-06-07', imgSrc: 'Images/media2024-06-07.jpg' },
    { id: 13, title: 'Amar Ujala', date: '2024-04-21', imgSrc: 'Images/media2024-04-21.jpg' },
    { id: 14, title: 'Navbharat Times', date: '2024-04-21', imgSrc: 'Images/media2024-04-21-2.jpg' },
    { id: 15, title: 'Navbharat Times', date: '2024-02-17', imgSrc: 'Images/media2024-02-17.jpg' },
    { id: 16, title: 'Navbharat Times', date: '2024-01-16', imgSrc: 'Images/media2024-01-16.jpg' },
    { id: 17, title: 'Navbharat Times', date: '2024-01-06', imgSrc: 'Images/media2024-01-01.jpg' },
    
    { id: 18, title: 'Delhi Glow News', date: '2023-12-30', imgSrc: 'Images/media-12-2023.jpg' },
    { id: 19, title: 'Amar Ujala', date: '2023-11-17', imgSrc: 'Images/media-11-2023.jpg' },
    { id: 20, title: 'Navbharat Times', date: '2023-11-15', imgSrc: 'Images/NBT-15-Nov-2023.jpg' },
    { id: 21, title: 'Navbharat Times', date: '2023-08-14', imgSrc: 'Images/NBT-14-Aug-23.jpg' },
    { id: 22, title: 'Hindustan Times', date: '2023-06-01', imgSrc: 'Images/NBT-Jun-23.jpg' },
    { id: 23, title: 'Navbharat Times', date: '2022-12-31', imgSrc: 'Images/media-2022-12-31.jpg' },
    { id: 24, title: 'Amar Ujala', date: '2022-12-16', imgSrc: 'Images/media-2022-12-16.jpg' },
    { id: 25, title: 'Navbharat Times', date: '2022-12-11', imgSrc: 'Images/media-2022-11-11.jpg' },
    { id: 26, title: 'Navbharat Times', date: '2022-05-14', imgSrc: 'Images/media-2022-05-14.jpg' },
    { id: 27, title: 'Hindustan Times', date: '2020-03-03', imgSrc: 'Images/Media-03-03-2020-1.jpg' },
    { id: 28, title: 'Hindustan Times', date: '2020-03-02', imgSrc: 'Images/Media-03-03-2020-2.jpg' },
    { id: 29, title: 'Amar Ujala', date: '2020-02-06', imgSrc: 'Images/media-2022-05-14.jpg' },
    { id: 30, title: 'Hindustan Times', date: '2020-01-27', imgSrc: 'Images/Media-03-03-2020-3.jpg' },
    { id: 31, title: 'Amar Ujala', date: '2019-09-01', imgSrc: 'Images/Media-2019-09-01.jpg' },
    { id: 32, title: 'Amar Ujala', date: '2019-09-13', imgSrc: 'Images/Media-2019-09-13.jpg' },
    { id: 33, title: 'Dainik Jagran', date: '2019-09-13', imgSrc: 'Images/2019-09-13-2.jpg' },
    { id: 34, title: 'Hindustan Times', date: '2019-07-29', imgSrc: 'Images/Media-2019-07-29.jpg' },
    { id: 35, title: 'Hindustan Times', date: '2018-12-24', imgSrc: 'Images/Media-04-02-19.jpg' },
    { id: 36, title: 'Hindustan Times', date: '2018-12-24', imgSrc: 'Images/Media-2018-12-18.jpg' },
    { id: 37, title: 'The Pioneer', date: '2018-03-28', imgSrc: 'Images/Media.jpg' }
  ];


  // Filter media items based on selected year and month
  const filteredMedia = mediaData.filter((item) => {
    const mediaYear = item.date.split('-')[0];
    const mediaMonth = item.date.split('-')[1];
    return (
      (!selectedYear || mediaYear === selectedYear) &&
      (!selectedMonth || mediaMonth === selectedMonth)
    );
  });

  // Get available months based on the selected year
  const availableMonths = months.filter((month) =>
    mediaData.some((item) => {
      const mediaYear = item.date.split('-')[0];
      const mediaMonth = item.date.split('-')[1];
      return mediaYear === selectedYear && mediaMonth === month.value;
    })
  );

  return (
    <>
      <HeadTag title="Media" />
      <Highlights />
      <InnerHaeder />
      <div className="inner_page">
        <div className="container-fluid flwidth">
          <div className="bgimage">
            <h5>
              <Link to="/"> Home <i className="fas fa-angle-right"></i> </Link>
              <span> Gallery <i className="fas fa-angle-right"></i> Media </span>
            </h5>
            <h2>Media</h2>
          </div>

          {/* Year Selection */}
          <div className="filter-section">
            <div className="row">
             
              <div className="col-md-12"> 
                <select value={selectedYear} onChange={(e) => {setSelectedYear(e.target.value);
                   setSelectedMonth('');}}>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                    Year   {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Month Tabs */}
          
          </div>

          <div className="row img_data_sec media-img">
            {filteredMedia.length > 0 ? (
              filteredMedia.map((item) => (
                <div className="col-xl-3 col-lg-4 col-md-6" key={item.id}>
                  <div className="box">
                    <a data-magnify="gallery" data-src="" data-group="a" href={item.imgSrc}>
                      <img src={item.imgSrc} className="img-fluid" alt={item.title} />
                    </a>
                    <h4>{item.title}</h4>
                    <p><i class="bi bi-calendar-week"></i> {item.date}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <p>No media found for the selected filters.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Media;
